.backLink {
    align-items: center;
    color: var(--app-dark-color);
    display: flex;
    font-weight: 500;
    transition: none !important;
}

.backLink span {
    margin-left: 8px;
}

.backLink:hover svg path {
    fill: var(--app-primary-color);
}

.smallScreenBackLink {
    display: none;
}

.titleWrapper {
    align-items: baseline;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.titleWrapper h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
}

.addText {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 16px;
    margin-top: 2px;
}

/*@media screen and (min-width: 300px) and (max-width: 799px) {*/
/*    .smallScreenBackLink {*/
/*        display: inline-block;*/
/*        margin-right: auto;*/
/*    }*/

/*    !*.backLink {*!*/
/*    !*    display: none;*!*/
/*    !*}*!*/

/*    .titleWrapper h1 {*/
/*        margin-left: -5px;*/
/*    }*/
/*}*/
