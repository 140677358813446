.tabButtons {
  margin-top: 20px;
  margin-bottom: 12px;

  &__button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: #262626;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 0px 8px 0px;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #00AAE6;
      font-weight: 500;
      border-bottom: 2px solid #00AAE6;
    }

    &_disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }

  &__activitiesModal {
    display: flex;
    margin-top: 8px;
    margin-bottom: 18px;
    border-bottom: 1px solid #EAECF0;
    position: relative;
  }

  &__activitiesModalButton {
    color: var(--accent, #667085);
    /* Text sm/Medium */
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #1755E7;
      font-weight: 500;
      border-bottom: 2px solid #1755E7;
    }
  }
}