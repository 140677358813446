.inner{
  width: 26%;
  border-radius: 8px 0 0 0;
  border: 1px solid #EEE;
  background: #FFF;
  min-width: 350px;
  &__top{
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 12px;
    border-bottom: 1px solid #EEE;
    p{
      margin-bottom: 0;
    }
  }
}
.more{
  cursor: pointer;
  height: 34px;
  position: relative;

}
.activityDetails{
  color: var(--headline-h-1, #161B25);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 16px 0 0 16px;
}
@media (max-width: 940px) {
  .inner{
    width: 100%;
    margin-top: 10px;
    min-width: 100%;
  }
}