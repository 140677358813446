.formWrapper {
  background-color: white;
  padding: 5px 30px 10px 30px;
}

.formItemWrapper {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 25px;
  padding-top: 6px;
}

.buttonsArea {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  margin: 0 auto;
}

.buttonsArea a {
  margin-bottom: 22px;
  margin-right: 15px;
}

.buttonsArea button {
  width: 105px;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .label {
    margin-top: 10px;
  }
  
  .buttonsArea {
    padding-top: 10px;
  }
}
