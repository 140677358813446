.header{
  text-align: center;
  margin: 0 auto;
  &__title{
    color: var(--Grey-Grey-90, #111827);
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 40px;
  }
  &__subtitle{
    color: var(--Grey-Grey-90, #111827);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 40px;
  }
  &__search{
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
    width: 53%;
    min-width: 536px;
    height: 48px;
    color: var(--Gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 16px;
  }
}
.section{
  border-bottom: 1px solid #EAECF0;
  &__title{
    color: var(--secondary, #2D2D2D);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 16px;
  }
}