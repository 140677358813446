.inner{
  position: absolute;
  max-height: 628px;
  overflow-x: hidden;
  right: 380px;
  top: 50px;
  height: 628px;
  background-color: #FFFFFF;
  z-index: 50;
  border-radius: 4px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.20);
  min-width: 440px;
  &__header{
    padding: 12px 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
    border-radius: 12px; /* Adjust the border radius as needed */
  }
  &::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Adjust as needed */
    border-radius: 12px;
  }
}
.title{
  color: var(--gray-gray-900, #1A1F36);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0;
}