.wrapper {
  background-color: white;
  padding: 10px;
  overflow: hidden;
}

.title {
  font-size: 18px;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  width: 275px
}

.spinWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.tableDesc{
  margin-top: 10px;
}