.inner {
  border-radius: 8px 0 0 8px;
  background: #FFF;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.20);
  max-width: 427px;
  width: 100%;

  &__header {
    display: flex;
    border-radius: 8px 0px 0px 0px;
    background: #424242;
    align-items: center;
    justify-content: space-between;
    height: 68px;

  }

  &__headerTitle {
    color: var(--basics-white, #FFF);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 16px;
  }

  &__headerButtons {
    display: flex;
    margin-right: 12px;

    button {
      height: 16px;
      width: 16px;
      padding: 0;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-left: 8px;
      cursor: pointer;
      transition: all .5s;
      &:hover{
        svg{
          path{
            fill: rgba(255, 255, 255, 0.6);
          }
        }
      }


    }

    button + button {
      margin-top: 2px;
    }
  }
}
.content{
  padding: 0 16px 24px;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}