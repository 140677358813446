.inner{
  margin-top: 13px;
  padding: 0 60px 80px 60px;
  &__pageTitle{
    padding-left: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__item{

  }
  &__itemHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 23px 4px 16px;
    p{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 34px;
      text-align: center;
      letter-spacing: -0.520833px;
      color: #000000;
      margin: 0;
    }
    border-bottom: 2px solid #E5E7EB;
  }
}
.formItem{
  &__label{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;
    display: block;
  }
  input{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px 14px;
  }
  textarea{
    padding: 10px 14px;
  }
}
.form{
  padding: 20px 16px;
}
.propertyAddresses{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
}
.fakeInput{
  background: #F9FAFB;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  padding: 10px 14px;
  p{
    margin-bottom: 0;
  }
  &__fakeLink{
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 60%;
    text-decoration-line: underline;
    color: #1890FF !important;
  }
  &__fakeLink_second{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 60%;
    text-decoration-line: underline;
    color: #1890FF !important;
    margin-top: 5px;
  }
}
.previewText{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: -0.520833px;
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 2px solid #E5E7EB;
  p{
    margin-bottom: 0;
    padding: 12px 20px;
  }
}
.previewMessage{
  background: #F8FAFC;
  border-radius: 8px;
  padding: 21px 16px 19px 12px;
  margin: 20px 20px;
  p{
    color: #000;
  }
}
.fakePropertyItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9FAFB;
  border: 1px solid #E7E8E9;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 0 16px 0 0;
  & + &{
    margin-top: 25px;
  }
  &__img{
    margin-right: 10px;
  }
  &__title{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #131313;
    margin-bottom: 4px;
  }
  &__text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: rgba(19, 19, 19, 0.75);
  }
  &__textInner{
    max-width: 640px;
    width: 100%;
  }
  &__owner{
    color: #1890FF !important;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.521px;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
.fakeTextarea{
  width: 100%;
  min-height: 150px;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: -0.520833px;
  color: #000000;
  overflow: hidden;
  resize: none;
}
.fakeSubject{
  margin: 20px;
  padding-bottom: 3px;
  border-bottom: 2px solid #E5E7EB;
}