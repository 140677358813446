.inner{
  margin-left: 42px;
  margin-top: 24px;
}
.title{
  color: var(--gray-900, #101828);
  font-family: Inter, 'sans-serif';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  margin-bottom: 4px;
}
.underTitleText{
  color: var(--gray-500, #667085);
  font-family: Inter, 'sans-serif';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}
.filterButtons{
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin-bottom: 10px;
  width: 48%;
  &__button{
    display: flex;
    cursor: pointer;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border: none;
    border-right: 1px solid var(--gray-300, #D0D5DD);
    background: var(--white, #FFF);
    color: var(--gray-800, #1D2939);
    font-family: Inter, 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &:first-child{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &_active{
      font-weight: 600;
      background-color: #F9FAFB;
    }
  }
}
.notifications{
  height: 540px;
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
    border-radius: 12px; /* Adjust the border radius as needed */
  }
  &::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Adjust as needed */
    border-radius: 12px;
  }
}
.notificationsNumber{
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(235, 87, 87, 0.12);
  color: #EB5757;
  text-align: center;
  font-family: Inter, 'sans-serif';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: fit-content;
  margin-bottom: 17px;
}
.header{
  position: relative;
  width: fit-content;
}
.notificationInfo{
  position: absolute;
  top: 0;
  right: -30px;
  cursor: pointer;
}
.infoBlock{
  position: absolute;
  top: 35px;
  border-radius: 8px;
  background: #F9FAFB;
  padding: 12px;
  height: 170px;
  width: 450px;
  z-index: 10;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.06), 0px 12px 16px -4px rgba(16, 24, 40, 0.12);
  h2{
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }
  &__item{
    display: flex;
    & + &{
      margin-top: 8px;
    }
  }
  &__badge{
    border-radius: 10px;
    display: flex;
    height: 18px;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    width: fit-content;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-right: 6px;
    &_required{
      background-color: #0707FA;
      color: #FFF;
    }
    &_hold{
      background-color: #FFF494;
      color: #000;
    }
    &_cancelled{
      color: #000;
      background-color: #FBB;
    }
    &_confirmed{
      color: #FFF;
      background: #12B76A;
    }
    &_sent{
      color: #FFF;
      background-color: #A0A0A0;
    }
  }
  &__text{
    color: #667085;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  &__items{
    display: flex;
    flex-direction: column;
  }
}
.notificationsInner{
  /* WebKit Browsers */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-button {
    background: #888; /* Color of the scrollbar button */
    border-radius: 50%; /* Round the scrollbar button */
  }

  &::-webkit-scrollbar-button:horizontal:decrement,
  &::-webkit-scrollbar-button:horizontal:increment,
  &::-webkit-scrollbar-button:vertical:decrement,
  &::-webkit-scrollbar-button:vertical:increment {
    height: 20px; /* Adjust height as needed */
    width: 20px; /* Adjust width as needed */
  }

  scrollbar-width: thin;
}
.notificationsTitle{
  color: var(--secondary-sidebar, #A0A0A0);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 271.429% */
  margin-bottom: 12px;
}
.switchButton{
  width: 32px;
  height: 32px;
  background-color: #2D2D2D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
  position: relative;
  z-index: 1000;
}
.editContact{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  &__name{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__company{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__email{
    color: var(--Gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  &__remove{
    color: var(--Error-700, #B42318);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
.datePicker{
  margin-left: 18px;
}
.notificationsBlock{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1130px) {
  .filterButtons{
    width: 100%;
  }
  .headerButtons{
    flex-direction: column;
  }
  .datePicker{
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  .mobileMenu{
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 940px) {
  .notificationsBlock{
    flex-direction: column-reverse;
  }
  .notificationsInner{
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .filterButtons{
    width: 100%;
  }
  .inner{
    margin: 24px;
  }
}
@media (max-width: 670px) {
  .filterButtons{
    overflow-x: scroll;
  }
}