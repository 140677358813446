.tableInner{
  margin-top: 45px;
  margin-left: 30px;
  border-radius: 8px;
  border: 1px solid var(--Grey-Grey-20, #E5E7EB);
  background: var(--Grey-Grey-0, #FFF);
  &__top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
.tableTitle{
  color: var(--Gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  margin: 0;
}
.tableHeader{
  padding: 20px 30px 12px 20px;
}
.selectItems{
  display: flex;
  gap: 8px;
}
.selectItem{
  display: flex;
  flex-direction: column;
  width: 240px;
  label{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
  }
}
.deleteBtn{
  color: var(--accent, #D92D20);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}
.form{
  width: 100%;
  &__row{
    width: 100%;
  }
  &__item{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    label{
      color: var(--Gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 6px;
    }
  }
  &__rowDivided{
    display: flex;
    gap: 8px;
  }
}