.form{
  width: 100%;
  &__item{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    &_fullWidth{
      max-width: 100%;
    }
  }
  &__label{
    color: var(--gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }
  &__dates{
    display: flex;
    justify-content: space-between;
    margin-top: 1px;
  }
  &__buttons{
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
  &__row{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
}
.errorText{
  color: var(--gray-700, #ff0000);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 6px;
}
.badge{
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--gray-100, #F2F4F7);
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  right: 0;
  top: -108px;
}
.switchButton{
  width: 32px;
  height: 32px;
  background-color: #2D2D2D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 73px;
  z-index: 100;
}
@media (max-width: 768px) {
  .form{
    &__buttons{
      flex-direction: column;
      gap: 10px;
      button{
        width: 100% !important;
      }
    }
  }
}
@media (max-width: 480px) {
  .form{
    &__row{
      flex-direction: column;
      gap: 12px;
    }
    &__dates{
      flex-direction: column;
      gap: 12px;
    }
  }

}