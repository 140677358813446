.table{
  border-radius: 8px;
  border: 1px solid var(--Grey-Grey-20, #E5E7EB);
  background: var(--Grey-Grey-0, #FFF);
  padding: 20px 20px 60px 20px;
  margin-top: 45px;
  margin-left: 30px;
  margin-right: 20px;
  &__title{
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    margin: 0;
  }
  &__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-right: 10px;
  }
}
.deleteBtn{
  color: #F00;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
}
.form{
  width: 100%;
  &__item{
    display: flex;
    flex-direction: column;
    width: 100%;
    & + &{
      margin-top: 16px;
    }
  }
}
.error{
  font-size: 12px;
  color: var(--gray-700, #ff0000);
  margin-top: 5px;
}