.tableWrapper {
  background-color: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 30px;
}

.tableWrapper tr {
  cursor: pointer;
}

.isUploadedColumn {
  align-items: center;
  display: flex;
}

.isUploadedColumn svg {
  margin-right: 8px;
}
.date{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #111827;
  margin-bottom: 0px;
  margin-left: 15px;
}