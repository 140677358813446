.button{
  border-radius: 8px;
  border: 1px solid #E6EAF4;
  background: #E6EAF4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1755E7;
  text-align: center;
  font-family: Inter, sans-serif;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  &_whiteBg{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
}