.formLabel{
  color: var(--gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
  margin-top: 6px;
}
.formInput{
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--white, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  max-width: 426px;
  min-width: 426px;
  width: 100%;
  padding: 10px 14px;
  color: var(--gray-900, #101828);
  font-family: Inter, sans-serif;
  height: 44px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  outline: #1A73E8;
  position: relative;
  &_md{
    min-width: 208px;
  }
  &_mdSmaller{
    min-width: 206px;
    max-width: 206px;
  }
  &_sm{
    min-width: 98px;
    max-width: 98px;
  }
  &__searchBtn{
    position: absolute;
    top: 71px;
    right: 0;
    border-radius: 0px 8px 8px 0px;
    background: var(--accent, #0707FA);
    height: 44px;
    border: none;
    width: 46px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &_filled{
      background-color: rgba(7, 7, 250, 0.26);
      border: 1px solid transparent;
      color: #FFF;
    }
  }
}
.shownAddress{
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 6px;
}
.positionSwitcher{
  display: flex;
  justify-content: space-between;
  width: 100%;
  p + p{
    margin-top: 0;
  }
  &__content{

  }
  &__positionText{
    color: var(--gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__abilityText{
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0;

  }
}