.search_input {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #9CA3AF;
  border: none;
  width: 210px;
  height: 32px;
  outline: none;
}

@media (max-width: 550px) {
  .search_input{
    width: 100%;
  }
}