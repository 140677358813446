.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper img {
  margin: 0 auto;
  width: 240px;
}

.successMessage {
  font-size: 24px;
  font-weight: 500;
  margin: 20px 0px;
  text-align: center;
}
