.wrapper {
  align-items: center;
  background-color: var(--app-dark-color);
  color: white;
  display: grid;
  grid-template-columns: 1fr 190px 1fr;
  padding: 16px 60px;
  position: relative;
}

.logo {
  height: 60px;
  width: 60px;
}

.appTitle {
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
}

.userData {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.nickname {
  font-size: 18px;
}

.date {
  font-size: 18px;
  margin-right: 8px;
}

.time {
  font-size: 16px;
  margin-left: 8px;
}

.userMenu {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  min-width: 100px;
  padding: 10px;
}

.userMenu svg {
  margin-right: 8px;
}
.nicknameArea{
  display: flex;
  align-items: center;
}
.notificationsCount{
  width: 12px;
  height: 12px;
  background-color: #EB5757;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 8px */
  border-radius: 50%;
  right: 0;
  top: 0;
}
@media (max-width: 1024px) {
  .wrapper{
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 801px) and (max-width: 1000px) {
  .wrapper {
    padding: 15px 15px;
  }

  .appTitle {
    font-size: 18px;
  }

  .nickname {
    font-size: 16px;
  }
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .wrapper {
    grid-template-columns: 75px 1fr;
    padding: 15px 30px;
  }

  .appTitle {
    display: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .wrapper {
    grid-template-columns: 65px 1fr;
    padding: 12px 10px;
  }

  .logo {
    height: 64px;
    width: 64px;
  }

  .date {
    font-size: 14px;
  }

  .time {
    font-size: 12px;
  }

  .appTitle,
  .nickname {
    display: none;
  }

  .userMenu {
    font-size: 12px;
    padding: 5px;
  }
}
