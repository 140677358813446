.inner{
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  position: relative;
}
.smText{
  //color: #808080;
  text-align: center;
  //font-family: Inter, sans-serif;
  //font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 40px;
}
.bgText{
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-bottom: 24px;
}
.saleBlock{
  position: relative;
}
.sale{
  position: absolute;
  top: 0;
  //right: -60px;
  p{
    position: absolute;
    top: 85px;
    color: #FFF;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    transform: rotate(-44.872deg);
    line-height: normal;
    text-transform: uppercase;
    right: 50px;
  }
}
.colorfulText{
  margin-top: 32px;
  width: 100%;
  margin-bottom: 12px;
  p{
    color: var(--color-for-email, #161616);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 30px; /* 125% */
  }
  span{
    color: #EB1C14;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}
.mdText{
  text-align: center;
  /* Text xl/Regular */
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  margin-bottom: 64px;
}
.links{
  display: flex;
  justify-content: space-between;
  padding-bottom: 105px;
  &__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    p{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      margin: 12px 0 12px;
    }
    a{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}
.companyAbbr{
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.topLine{
  height: 1px;
  width: 100%;
  background-color: #f00;
  margin-bottom: 30px;
}
.botLine{
  height: 1px;
  width: 100%;
  background-color: #f00;
  margin-top: 30px;
}
@media screen and (max-width: 1000px) {
  .inner{
   padding: 0 10px;
  }
}
@media screen and (max-width: 700px) {
  .inner{
    padding: 0 10px;
  }
  .saleBlock{
    img{
      height: 240px !important;
    }
  }
  .links{
    flex-direction: column;
    padding-bottom: 50px;
    &__link{
      & + &{
        margin-top: 20px;
      }
    }
  }
  .headerLogo{
    max-width: 260px !important;
  }
  .sale{
    height: 240px;
    left: -32px;
    svg{
      height: 240px;
    }
  }
}