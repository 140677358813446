.label {
  display: flex;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 25px;
  padding-top: 6px;
}

.domainOptions label {
  font-size: 14px;
  height: auto;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .label {
    margin-top: 10px;
  }
  
  .domainOptions {
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    text-align: center;
  }
}
