.button{
  border-radius: 8px;
  border: 1px solid #1755E7;
  background: #1755E7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Inter, sans-serif;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  height: 40px;
  cursor: pointer;
}