.tableTitle{
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #111827;
}
.search_input {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #9CA3AF;
  border: none;
  width: 270px;
  height: 32px;
  outline: none;
}
.tableHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  &__buttons{
    display: flex;
    gap: 12px;
  }
}
.footerText{
  margin-bottom: 0;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
}
.textArea{
  border: none;
  outline: none;
  border-radius: 0;
  border-color: transparent!important;
  box-shadow: none!important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
}
.deleteCode{
  border: none;
  outline: none;
  color: #F00;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
}