.title{
  color: #000;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  margin-top: 0;
  margin-bottom: 30px;
}
.spinnerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
}
.contentWrapper{
  display: flex;
  justify-content: space-between;
}