.checkbox{
  margin-bottom: 32px;
  &__text{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: 8px;
  }
  p{
    color: var(--Gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 8px 0 0 0;
  }
}
.form{
  &__buttons{
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}