.wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
}

.contentWrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin: 30px;
  width: 500px;
}

.formHeader {
  background-color: var(--app-dark-color);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-top: 50px;
  padding-top: 60px;
  padding-bottom: 15px;
  position: relative;
  text-align: center;
}

.logo {
  left: 0;
  right: 0;
  position: absolute;
  top: -40px;
  width: 100%;
}

.formHeader h1 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}

.formHeader h2 {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.signInForm {
  background: #FFFFFF;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 0px 8px rgba(209, 213, 219, 0.2);
  padding: 30px 60px;
}
.footer{
  padding: 0 32px 20px 32px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  &__inner{
    width: 100%;
    border-top: 1px solid #A0A0A0;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    display: flex;
    align-items: center;
    img{
      width: 40px;
      height: 40px;
    }
    p{
      color: #2D2D2D;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-left: 12px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  &__links{
    display: flex;
    align-items: center;
    a{
      color: var(--Accent, #1755E7);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    p{
      color: var(--Accent, #1755E7);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 799px) {
  .signInForm {
    padding: 10px;
  }
}
