.inner{
  display: flex;
  &__search{
    display: flex;
    height: 44px;
    align-items: center;
    position: relative;
  }
  &__inputBox{
    position: relative;
  }
  &__form{
    margin-right: 10px;
  }
}
.searchBtn{
  margin-left: 16px;
  border-radius: 8px;
  border: 1px solid var(--Accent, #1755E7);
  background: var(--Accent, #1755E7);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--White, #FFF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 44px;
  width: 100px;
  cursor: pointer;
}
.cancelRequest{
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 352px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
}
.showMore{
  color: var(--accent-for-forms, #0707FA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}
.formattedDate{
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  letter-spacing: 0.1px;
  margin-right: 6px;
}
.formattedHours{
  color: #7D7D7D;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  letter-spacing: 0.1px;
  margin-left: 6px;
}
.dateIcon{
  border-radius: 1px;
  background: #E3E3E3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 12px;
  margin-right: 6px;
}
.ownerName{
  color: #111827;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
  margin-right: 8px;
  margin-bottom: 2px;
}
.ownerEmail{
  color: rgba(17, 24, 39, 0.80);
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  letter-spacing: 0.15px;
  margin-bottom: 2px;
}
.ownerPhone{
  color: var(--secondary, #2D2D2D);
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  letter-spacing: 0.15px;
  margin-bottom: 0;
}
.ownerDivider{
  background: #A0A0A0;
  width: 1px;
  height: 12px;
  margin: 0 9px;
}
.ownerItem{
  & + &{
    margin-top: 5px;
  }
}
.infoBlock{
  position: absolute;
  top: 100px;
  border-radius: 8px;
  background: #F9FAFB;
  padding: 12px;
  height: 210px;
  width: 500px;
  z-index: 1000;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.06), 0px 12px 16px -4px rgba(16, 24, 40, 0.12);
  h2{
    color: #344054;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }
  &__item{
    display: flex;
    & + &{
      margin-top: 8px;
    }
  }
  &__badge{
    border-radius: 10px;
    display: flex;
    height: 18px;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    width: fit-content;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-right: 6px;
    &_green{
      background-color: #12B76A;
      color: #FFF;
    }
    &_red{
      background-color: #F04438;
      color: #FFF;
    }
    &_gray{
      color: #FFF;
      background-color: #666464;
    }
    &_blue{
      color: #FFF;
      background: #3863f0;
    }
    &_orange{
      color: #FFF;
      background-color: #ed750c;
    }
  }
  &__text{
    color: #667085;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  &__items{
    display: flex;
    flex-direction: column;
  }
}
.gridTitle{
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: rgb(107, 114, 128);
  font-size: 12px;
  line-height: 20px;
  padding: 10px;
  margin-bottom: 0;
}
.propertyRow{
  transition: .3s;
  padding: 2px 5px;
  &:hover{
    background-color: rgb(226, 237, 248) !important;
  }
}
.total{
  color: #111827;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
  margin-right: 20px;
  text-align: right;
  margin-top: 5px;
}