.inner{
  margin-left: 130px;
  padding-top: 75px;
}
.title{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  margin-top: 12px;
  margin-bottom: 40px;
}
.formSteps{
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  height: 68px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--White, #FFF);
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  width: 78%;
  & > div{
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    p{
      margin-bottom: 0;
    }
  }
  &__title{
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: 4px;
    margin-right: 20px;
    margin-bottom: 0;
  }
  &__icon{
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  &__arrowImg{
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
.form{
  max-width: 78%;

  &__inner{
    //padding-bottom: 16px;
    //border-bottom: 1px solid #A0A0A0;
    margin-bottom: 16px;
  }
  &__row{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
    label{
      color: var(--Gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      span{
        color: var(--accent, #0707FA);
      }
    }
    input{
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Gray-500, #344054);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      height: 44px;
    }
    & + & {
      margin-top: 16px;
    }

  }
  &__rowDouble{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 16px;
    .form__row + .form__row{
      margin-top: 0;
    }
  }
  &__asterics{
    display: flex;
    align-items: center;
    gap: 12px;
    p{
      color: var(--secondary-sidebar, #A0A0A0);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 0;
    }
    span{
      color: var(--accent, #0707FA);
    }
  }
  &__error{
    font-size: 12px;
    color: var(--gray-700, #ff0000);
    margin-bottom: 0;
  }
  &__rowMobile{
    display: flex;
    gap: 16px;
  }
}
.addBtn{
  color: var(--accent, #0707FA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  border: 1px dashed var(--accent, #0707FA);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 40px;
  width: 100%;
  margin-bottom: 16px;
}
.buttons{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 52px;
}
.contactNumber{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 225% */
}

.skipOrgText{
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 343px;
  width: 100%;
}
.note{
  margin-top: 15px;
  p{
    color: var(--secondary-sidebar, #A0A0A0);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .inner{
    margin: 0 40px;
    padding-top: 30px;
  }
  .formSteps{
    width: 100%;
  }
  .form{
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .inner{
    margin: 0 20px;
  }
  .formSteps{
    padding: 0 12px;
  }
}
@media (max-width: 480px) {
  .prahaBlock {
    &__checkBoxGroup {
      column-gap: 20px;
    }
  }
  .form{
    &__rowDouble{
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 16px;
      .form__row + .form__row{
        margin-top: 0;
      }
    }
  }
}