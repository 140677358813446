.modal{
  width: 740px !important;
}
.modalHeader {
  align-items: center;
  background-color: var(--app-dark-color);
  display: flex;
  justify-content: center;
  padding: 25px;
  border-radius: 8px 8px 0 0;
}

.modalHeader svg {
  cursor: pointer;
  margin-left: auto;
}

.modalHeader h2 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0;
  margin-left: auto;
}

.formWrapper {
  padding: 25px;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btnWrapper button {
  width: 106px;
  height: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.btnWrapper button:first-of-type {
  margin-right: 10px;
}

.uploadDragger {
  display: flex;
  padding: 0 10px;
}

.uploadDragger svg {
  margin-right: 15px;
}

.uploadDragger .text {
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.uploadDragger .details {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.uploadInner{
  display: flex;
  align-items: center;
}
.uploadInner span{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.uploadInnerMarketing{
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 2px;
}
.uploadInner span{
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #6B7280;
}
.uploadInner__marketing{
  display: flex;
  flex-direction: column;
  max-width: 120px;
  width: 100%;
  margin-right: 49px;
  font-size: 12px;
}
.marketingTitle{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.redDot{
  color: #F43F5E;
}
.domainText{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 5px;
}
.downloadInner{
  display: flex;
  position: relative;
  z-index: 1000;
  align-items: center;
}
.uploadedFileData{
  border: 1px dashed #1890FF;
  border-radius: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 58px;
  justify-content: space-between;
  padding: 5px 20px 5px 9px;
  cursor: pointer;
}
.uploadedFileData:hover{
  border: 1px solid #EDEDED;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  color: var(--app-primary-color);
}
.filename{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1890FF;
  margin-bottom: 0;
  height: 22px;
  margin-left: 8px;
}
.removePhotoBtn:hover svg path{
  fill: #1890FF;
  fill-opacity: 1;
}
.dateDownloaded{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #999999;
}