.wrapper {
  padding-bottom: 30px;
}

.wrapper h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 40px;
  margin-bottom: auto;
  padding-bottom: 40px;
  text-align: center;
}

.contentWrapper {
  display: flex;
  flex-direction:column;
  height: 100%;
  min-height: calc(100vh - 150px);
}

.titleWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.titleWrapper h1 {
  margin-left: auto;
  margin-right: auto;
}

.titleWrapper a {
  margin-left: auto;
  margin-right: 15px;
}

.panelWrapper:nth-child(1) {
  grid-area: item1;
}

.panelWrapper:nth-child(2) {
  grid-area: item2;
}

.panelWrapper:nth-child(3) {
  grid-area: item3;
}

.panelWrapper:nth-child(4) {
  grid-area: item4;
}

.panelWrapper:nth-child(5) {
  grid-area: item5;
}

.panelWrapper {
  column-gap: 40px;
  display: grid;
  /* grid-template-columns: repeat(12, 1fr); */
  grid-template-columns: repeat(6, 150px);

  /* grid-template-columns: repeat(3, 300px); */
  justify-content: center;
  margin-bottom: auto;
  row-gap: 40px;
  padding-bottom: 30px;
  width: 100%;
}

.item0 {
  grid-column: 1/3;
}

.item1 {
  grid-column: 3/5;
}

.item2 {
  grid-column: 5/7;
}

.item3 {
  grid-column: 2/4;
}

.item4 {
  grid-column: 4/7;
}

.panelItem {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 8px;
  cursor: pointer;
  color: var(--app-text-color);
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 45px 55px;
  text-align: center;
  width: 300px;
}

.panelItem svg {
  margin-bottom: 30px;
}

.panelItem:hover {
  border: 1px solid #EDEDED;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  color: var(--app-primary-color);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    padding: 0 30px 30px 30px;
  }

  .wrapper h1 {
    font-size: 20px;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  .panelWrapper {
    column-gap: 20px;
    grid-template-columns: repeat(2, 125px);
    row-gap: 20px;
  }

  .item0 {
    grid-column: 1/3;
  }

  .item1 {
    grid-column: 3/5;
  }

  .item2 {
    grid-column: 1/3;
  }

  .item3 {
    grid-column: 3/5;
  }

  .item4 {
    grid-column: 2/4;
  }

  .panelItem {
    padding: 25px 10px;
    width: 250px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .wrapper {
    padding: 0 10px 20px 10px;
  }

  .wrapper h1 {
    font-size: 20px;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  .panelWrapper {
    column-gap: 20px;
    grid-template-columns: repeat(4, 90px);
    row-gap: 20px;
  }

  .item0 {
    grid-column: 1/3;
  }

  .item1 {
    grid-column: 3/5;
  }

  .item2 {
    grid-column: 1/3;
  }

  .item3 {
    grid-column: 3/5;
  }

  .item4 {
    grid-column: 2/4;
  }

  .panelItem {
    font-size: 16px;
    padding: 25px 10px;
    width: 180px;
  }
}

@media screen and (min-width: 300px) and (max-width: 479px) {
  .wrapper {
    padding: 0 5px 20px 5px;
  }

  .wrapper h1 {
    font-size: 20px;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  .panelWrapper {
    column-gap: 10px;
    grid-template-columns: repeat(4, 80px);
    row-gap: 10px;
  }

  .item0 {
    grid-column: 1/3;
  }

  .item1 {
    grid-column: 3/5;
  }

  .item2 {
    grid-column: 1/3;
  }

  .item3 {
    grid-column: 3/5;
  }

  .item4 {
    grid-column: 2/4;
  }

  .panelItem {
    font-size: 16px;
    padding: 25px 10px;
    width: 160px;
  }
}
