.modal{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &_freePos{
    top: -100px;
    left: -20px;
  }
  &__inner{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 408px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: fit-content;
    padding: 24px;
    position: relative;
    justify-content: center;
  }
  &__innerContent{
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-right: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
      border-radius: 12px; /* Adjust the border radius as needed */
    }
    &::-webkit-scrollbar {
      width: 10px; /* Adjust the width of the scrollbar */
      background-color: transparent; /* Adjust as needed */
      border-radius: 12px;
    }

  }
  &__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      margin: 20px 0;
      color: var(--Gray-900, #101828);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

@media (max-width: 768px) {
  .modal{
    padding: 0 16px;
  }
}

@media (max-width: 480px) {
  .buttons{
    display: flex;
    flex-direction: column;
    margin-top: 32px !important;
  }
}