.inner{
  position: relative;
  & + &{
    margin-left: 4px;
  }
}
.buttons{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
}
.btnInner{
  display: flex;
  align-items: center;
  padding: 6px 12px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  width: max-content;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  & + &{
    margin-left: 4px;
  }
  p{
    margin: 0 5px 0 0;

  }
  &__modal{
    position: absolute;
    display: flex;
    flex-direction: column;
    minWidth: 265px;
    max-width: 700px;
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 5;
    padding: 12px 12px 18px 12px;
    left: 36px;
    top: -7px;
    p{
      margin-bottom: 12px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }
  &_black{
    background-color: black;
    color: #fff;
  }
  &_white{
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
  }
}
.select{
  position: relative;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  //appearance: none;
  outline: none;
  appearance: none; /* remove default arrow */
  background-image: url("https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-down-01-512.png");
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  cursor: pointer;
  &::after{
    content: "";
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 1;
  }
}
@media (max-width: 768px) {
  .btnInner{
    border: none;
    color: var(--Ink-Darkest, #090A0A);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 0;
    width: 100%;
    height: 56px;
    margin-bottom: 6px;
    & + &{
      margin-top: 16px;
    }
    &__modal{
      width: 100%;
      position: relative;
      box-shadow: none;
      border: none;
      left: 0;
      padding: 12px 0;
    }
    p{
      color: var(--Ink-Darkest, #090A0A);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .buttons{
    flex-direction: column;
    gap: 12px;
  }
}
