.inner{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 98vw;
  height: 100vh;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &__content{
    padding: 24px;
    position: absolute;
    min-height: 785px;
    height: 95vh;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    top: 2%;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &__header{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__headerTitle{
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  &__headerText{
    color: var(--gray-500, #667085);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
.formWithMap{

  &__content{
    display: flex;
    justify-content: space-between;
  }
}
.confirmPopup{
  border-radius: 8px;
  background: #101828;
  &__button{
    margin-top: 4px;
    border-radius: 8px;
    border: 1px solid var(--accent, #0707FA);
    background: var(--accent, #0707FA);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--white, #FFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 3px 13px;
    cursor: pointer;
  }
}
.cancelBtn{
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--white, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  min-width: 289px;
  min-height: 44px;
  cursor: pointer;
}
.errorMessage{
  color: var(--error-500, #F04438);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 6px;
}
.positionSwitcher{
  display: flex;
  justify-content: space-between;
  width: 100%;
  p + p{
    margin-top: 0;
  }
  &__content{

  }
  &__positionText{
    color: var(--gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__abilityText{
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0;

  }
}
.shownAddress{
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 0;
  margin-top: 6px;
}