.mobileFiltersButton{
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin:  0 20px 10px 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
