.logo{
  width: 75px;
  height: 75px;
}
.header{
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid #000;
  &__text{
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
.inner{
  padding: 20px 120px 90px;
  ul{
    li{
      margin-top: 16px;
      span{
        font-weight: 700;
      }
    }
  }
}
h1{
  margin: 30px 0;
  color: var(--Gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.item{
  & + &{
    margin-top: 20px;
  }
  h2{
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 32px;
    line-height: 48px;
    font-style: normal;
    font-weight: 600;
    margin: 0 0 12px 0;
  }
  h3{
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin: 0 0 12px 0;
  }
  h4{
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin: 0 0 12px 0;
  }
  p{
    color: var(--color-for-email, #161616);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0;
  }
  a{
    color: var(--Link, #337EFF);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration: none;
  }
  p + p{
    margin-top: 16px;
  }
}
