.inner{
  padding: 0 0 26px 20px;
  position: relative;
  margin-top: 20px;
}
.filterModal{
  position: absolute;
}
.tableInner{
  padding: 20px 0 0 0;
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
}
.rowsPerPage{
  height: 40px;
  margin: 0;
  border-top: none;
  border-bottom: none;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}
.footerText{
  margin-bottom: 0;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
  margin-top: 0;
}
.tableTitle{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0 0 7px 20px;
  color: #111827;
}
.columnHeader {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #6B7280;
}