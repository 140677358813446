.item {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
  border: 1px solid #F2F2F2;
  min-height: 100px;
  height: fit-content;
  position: relative;
  cursor: pointer;

  & + & {
    margin-top: 6px;
  }

  &_action_required {
    background: rgba(7, 7, 250, 0.08);
  }

  &_hold {
    background: rgba(255, 244, 148, 0.08);
  }

  &_confirmed {
    background: rgba(18, 183, 106, 0.08);
  }

  &_cancelled {
    background: rgba(255, 187, 187, 0.08);
  }

  &_sent {
    background: rgba(182, 202, 255, 0.08);
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0;
  }

  &__priority {
    display: flex;
    height: 18px;
    padding: 6px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    color: #000;
    font-family: Inter, 'sans-serif';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-right: 8px;

    &_required {
      background: #0707FA;
      color: #FFF;
    }

    &_hold {
      background: #FFF494;
    }

    &_confirmed {
      background: #12B76A;
      color: #FFF;
    }

    &_cancelled {
      background: #FBB;
    }

    &_sent {
      background: #A0A0A0;
    }
  }

  &_modal {
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 12px;
    height: auto;
  }

  &__before {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;

    &_required {
      background: #0707FA;
    }

    &_hold {
      background: #FFF494;
    }

    &_confirmed {
      background: #12B76A;
    }

    &_cancelled {
      background: #FBB;
    }

    &_sent {
      background: #A0A0A0;
    }
  }
}

.validTill {
  color: #000;
  font-family: Inter, 'sans-serif';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-left: 6px;
}

.reminder {
  color: #000;
  font-family: Inter, 'sans-serif';
  font-size: 13px;
  max-width: 80%;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-left: 10px;

  span {
    color: #1890FF;
    font-family: Inter, 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.actionsBtn {
  position: absolute;
  right: 12px;
  top: 26px;
  display: flex;
  padding: 6px 12px 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey-grey-20, #E5E7EB);
  background: var(--grey-grey-0, #FFF);
  color: var(--grey-grey-90, #111827);
  font-family: Inter, 'sans-serif';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.comment {
  display: flex;
  gap: 5px;
  color: var(--grey-grey-90, #111827);
  font-family: Inter, 'sans-serif';
  font-size: 12px;
}

@media (max-width: 670px) {
  .actionsBtn {
    position: relative;
    width: 100%;
    right: 0;
  }
  .item{
    &__top{
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
    }
  }
  .reminder{
    max-width: 100%;
  }
  .actionsBtn{
    top: -2px;
  }
}