.modal{
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &__inner{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 408px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: fit-content;
    padding: 24px;
    position: relative;
  }
  &__innerContent{
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    flex-direction: column;
    align-items: center;
    display: flex;
    padding-right: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
      border-radius: 12px; /* Adjust the border radius as needed */
    }
    &::-webkit-scrollbar {
      width: 10px; /* Adjust the width of the scrollbar */
      background-color: transparent; /* Adjust as needed */
      border-radius: 12px;
    }

  }
  &__logo{
    border-radius: 28px;
    border: 8px solid var(--primary-50, #F9F5FF);
    background: #F5F5FF;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  &__title{
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.closeModal{
  position: absolute;
  right: 1px;
  top: 3px;
  background-color: transparent;
  outline: none;
}
.form{
  width: 100%;
  &__item{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    &_fullWidth{
      max-width: 100%;
    }
  }
  &__label{
    color: var(--gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }
  &__dividedRow{
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
  &__buttons{
    display: flex;
    gap: 12px;
    width: 100%;
  }
  &__row{
    margin-bottom: 16px;
  }
}
.error{
  font-size: 12px;
  color: var(--gray-700, #ff0000);
  margin-top: 5px;
}
.checkboxes {
  margin-top: 16px;
  &__prop {
    display: flex;
    & + &{
      margin-top: 16px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-left: 8px;
    span {
      &:first-child {
        color: var(--Gray-700, #344054);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      &:nth-child(2){
        color: var(--Gray-500, #667085);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}