.inner{
  margin-top: 13px;
  padding: 0 60px;
  &__pageTitle{
    padding-left: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
.date{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #111827;
}
.columnText{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
}
.rowsPerPage{
  height: 40px;
  margin: 0;
  border-top: none;
  border-bottom: none;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}
.footerText{
  margin-bottom: 0;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
}
.tabButtons {
  margin-bottom: 12px;

  &__button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: #262626;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 0;

    & + & {
      margin-left: 18px;
    }

    &_active {
      color: #00AAE6;
      font-weight: 500;
      border-bottom: 2px solid #00AAE6;
    }

    &_disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }
}
@media (max-width: 840px) {
  .inner{
    padding: 0 20px;
  }
}
