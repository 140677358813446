.tableWrapper {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  display: grid;
  grid-template-rows: repeat(17, auto);
  grid-auto-flow: column;
}

.columnTitle {
  align-items: center;
  display: flex;
  font-weight: 600;
  height: 100%;
}

.columnTitle,
.columnName {
  background: #E9EEF9;
}

.columnTitle,
.columnName,
.column {
  border: none;
  border-bottom: 1px solid #E5E7EB;
  border-radius: 0px;
  font-size: 12px;
  padding: 10px 24px;
  width: 100%;
}

.columnTitle:first-of-type {
  border-top-left-radius: 8px;
}

.lastColumnTitle {
  border-top-right-radius: 8px;
}

.lastColumnName {
  border-bottom-left-radius: 8px;
}

.column:last-of-type {
  border-bottom-right-radius: 8px;
}

.column {
  background-color: white;
  overflow: auto;
}

textarea.column:focus,
.column {
  box-shadow: none !important;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btnWrapper button {
  width: 106px;
}

.btnWrapper a {
  margin-right: 10px;
}
