.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper h1 {
  padding-bottom: 12px !important;
}

.contentWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: auto;
}
.label {
  display: flex;
  font-weight: 600;
  margin-bottom: 6px;
  padding-top: 6px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 300px) and (max-width: 479px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}
