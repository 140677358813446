.defaultInner{
  position: absolute;
  top: 8px;
  right: 20px;
  min-width: 127px;
  border-radius: 4px;
  background: var(--gray-gray-600, #515669);
  z-index: 100;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  &__item{
    color: var(--gray-white, #FFF);
    font-family: Inter, 'sans-serif';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 4px;
    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover{
      background: var(--blue-blue-100, #A4B1F4);
    }
  }

}
.inner{
  position: absolute;
  top: 25px;
  right: 90px;
  min-width: 127px;
  border-radius: 4px;
  background: var(--gray-gray-600, #515669);
  z-index: 100;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  &__item{
    color: var(--gray-white, #FFF);
    font-family: Inter, 'sans-serif';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 4px;
    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover{
      background: var(--blue-blue-100, #A4B1F4);
    }
  }

}

.headerInner{
  position: absolute;
  top: 46px;
  right: 40px;
  min-width: 127px;
  border-radius: 4px;
  background: var(--gray-gray-600, #515669);
  z-index: 100;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  &__item{
    color: var(--gray-white, #FFF);
    font-family: Inter, 'sans-serif';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 4px;
    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover{
      background: var(--blue-blue-100, #A4B1F4);
    }
  }

}