.contentWrapper {
  column-gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
}

.parameterWrapper {
  align-items: center;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-self: center;
  max-width: 800px;
  padding: 28px 24px;
  width: 100%;
  background-color: #fff;
}

.parameterWrapper:hover {
  border: 1px solid #EDEDED;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  color: var(--app-primary-color);

}
.parameterWrapper:hover .text h2{
  color: var(--app-primary-color);
}
.parameterWrapper .text {
  margin-left: 24px;
}

.parameterWrapper .text h2 {
  color: #111827;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px;
}

.parameterWrapper .text .description {
  color: #6B7280;
  font-size: 14px;
  line-height: 24px;
}

.parameterWrapper .arrow {
  margin-left: auto;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 300px) and (max-width: 479px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}
