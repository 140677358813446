.inner{
  margin-left: 64px;
  margin-right: 85px;
  &__content{
    margin-top: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EAECF0;
    margin-bottom: 27px;
  }
}
.form{
  &__row{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    gap: 6px;
    label{
      color: var(--Gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    input{
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Gray-500, #344054);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      height: 44px;
      &::placeholder{
        color: #667085;
        opacity: 0.6;
      }
    }
    & + & {
      margin-top: 16px;
    }
  }
  &__asterics{
    display: flex;
    align-items: center;
    gap: 12px;
    p{
      color: var(--secondary-sidebar, #A0A0A0);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 0;
    }
    span{
      color: var(--accent, #0707FA);
    }
  }
  &__error{
    font-size: 12px;
    color: var(--gray-700, #ff0000);
    margin-bottom: 0;
  }
  &__rowMobile{
    display: flex;
    gap: 16px;
  }
  &__rowDouble{
    display: flex;
    margin-bottom: 12px;
    .form__row + .form__row{
      margin-top: 0;
    }
  }
  &__checkbox{
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14.297px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__label{
    margin-top: 3px;
  }
}
.propertyAddress{
  color: var(--Gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 16px;
}
.mapPin{
  color: var(--accent-for-forms, #0707FA);
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 12px 0;
  cursor: pointer;
}
.tabButtons{
  display: flex;
  margin-top: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EAECF0;
  position: relative;
  &__button {
    min-width: 90px;
    color: var(--accent, #667085);
    /* Text sm/Medium */
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;

    & + & {
      margin-left: 15px;
    }

    &_active {
      color: #1755E7;
      font-weight: 500;
      border-bottom: 2px solid #1755E7;
    }
  }
}
.created{
  color: var(--headline-h1, #161B25);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span{
    color: var(--headline-h2, #6F7277);
  }
}
.buttons{
  display: flex;
  gap: 12px;
}



.picture{
  padding: 28px 16px;
  display: flex;
  border-bottom: 1px solid #EAECF0;
  position: relative;
  &:last-child{
    border-bottom: none;
  }
  &__size{
    overflow: hidden;
    color: var(--secondary-sidebar, #A0A0A0);
    text-overflow: ellipsis;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
  }
  &__id{
    overflow: hidden;
    color: var(--color-for-email, #161616);
    text-overflow: ellipsis;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__overlay{
    position: absolute;
    z-index: 45;
    bottom: 0;
    left: 0;
    height: 68px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(12px);
    gap: 20px;
    opacity: 0;
  }
  &__img{
    margin-right: 32px;
    max-width: 376px;
    min-width: 376px;
    width: 100%;
    height: 264px;
    border-radius: 4px;
    & > span{
      max-width: 376px;
      min-width: 376px;
      width: 100%;
      height: 264px;
      border-radius: 4px;
    }
    img{
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
    &:hover{
      .picture__overlay{
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }
  &__topBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6px;
  }
  &__kind{
    margin: 0;
    font-size: 14px;
  }

  &__icon{
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
    &:hover{
      transform: scale(1.2);
      color: #f0f0f0;
    }
  }
}
.picturesInner{
  height: 50vh;
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
    border-radius: 12px; /* Adjust the border radius as needed */
  }
  &::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Adjust as needed */
    border-radius: 12px;
  }
}
.showMore{
  display: flex;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
  p{
    color: var(--Accent-in-grid, #1755E7);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
  svg{
    margin-top: 2px;
    margin-left: 4px;
  }
}
.additionalParams{
  margin-top: 6px;
  p{
    margin-bottom: 6px;
  }
  &__checkboxes{
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }
}
.pictureRow {
  display: flex;
  flex-wrap: nowrap; // Prevent items from wrapping mid-row
  justify-content: flex-start;
  gap: 10px; // Adjust spacing between images
}
.pictureRowsContainer {

}

.pictureRow {
  display: flex;
  flex-wrap: nowrap; /* ✅ Keep items in the same row */
  justify-content: flex-start;
  gap: 10px;
}

.pictures {
  width: calc(25% - 10px); /* ✅ 4 items per row */
  max-width:  calc(25% - 10px) !important;
  min-width: 120px;
  height: auto;
  cursor: grab;
  position: relative;
}
.picturesList{
  width: 100%;
}
.overlayBlock{
  position: absolute;
  z-index: 45;
  bottom: 0;
  left: 0;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(12px);
  gap: 20px;
  padding: 24px;
  opacity: 1;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  p{
    color: var(--Background, #FCFEFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
  }
}
.overlayBlockActive{
  position: absolute;
  z-index: 45;
  bottom: 0;
  left: 0;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(12px);
  gap: 20px;
  padding: 24px;
  opacity: 1;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  p{
    color: var(--Background, #FCFEFF);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
  }
}
.pictureRowsContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between rows */
  min-height: 150px;
  .pictures{
    width: calc(25% - 10px); /* ✅ Ensures 4 items per row */
    min-width: 120px;
    height: auto;
    cursor: grab;
  }
  .picture{
    width: 100%;
    padding: 0;
    border-radius: 8px;
    &__img{
      margin-right: 32px;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: 264px;
      border-radius: 8px;
      & > span{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 264px;
        border-radius: 8px;
      }
      img{
        border-radius: 8px;
        width: 100%;
      }
      &:hover{
        .picture__overlay{
          opacity: 1;
          transition: all 0.5s;
        }
      }
    }
  }
}
.deleteBtn{
  border-radius: 8px;
  border: 1px solid var(--Primary-50, #F9F5FF);
  background: rgba(217, 45, 32, 0.12);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #D92D20;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.emptyDropZone {
  flex-grow: 1; /* Takes up all available space */
  min-height: 262px; /* Adjust based on UI */
  background-color: transparent; /* Invisible */
  transition: background-color 0.2s ease-in-out;
}
.viewButtons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--secondary-sidebar, #A0A0A0);
  width: 128px;
  height: 54px;
  &__button{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: .3s;
    &:hover{
      svg{
        path{
          stroke: #0707FA;
        }
      }
    }
    &_active{
      svg{
        path{
          stroke: #0707FA;
        }
      }
    }
  }
}
.buttonDivider{
  width: 1px;
  height: 30px;
  background: #A0A0A0;
}
.deleteCheckbox{
  position: absolute;
  top: 35px;
  left: 30px;
  z-index: 35;
}
.deleteCheckboxTable{
  position: absolute;
  top: 7px;
  left: 12px;
  z-index: 35;
}
.uploadPropertyText{
  color: var(--Gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  margin-bottom: 0;
}
.uploadMultipleText{
  color: var(--Gray-500, #667085);
  /* Text sm/Regular */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 0;
  margin-bottom: 0;
}
.uploadText{
  color: var(--Gray-500, #667085);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  span{
    color: var(--Accent-in-grid, #1755E7);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
}
.categoryLabel{
  overflow: hidden;
  color: var(--color-for-email, #161616);
  text-overflow: ellipsis;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
.categoryContainer{
  padding: 10px;
  transition: border 0.2s ease-in-out;
  border: 2px solid transparent; /* Default: No border */
  & + &{
    margin-top: 30px;
  }
  &.highlightBorder {
    border: 2px dashed red; /* Visible border on drag hover */
  }

}

.emptyCategory{
  height: 150px;
}
.activeCategory {
  border: 2px solid rgb(16, 24, 40, 0.3);
  transition: background-color 0.2s ease-in-out;
}


@media (max-width: 1380px) {
  .pictureTable {
    .pictures {
      width: 23.4%;
    }
  }
}